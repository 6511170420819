
   
   @keyframes changeBackground {
     0% { background-color: rgb(117, 170, 239); } 
     25% { background-color: rgb(59, 112, 212); } 
     50% { background-color: rgb(22, 62, 171); }
     75% { background-color: rgb(10, 34, 133); } 
     100% { background-color: rgb(8, 12, 119); } 
   }
   
   
   .paperplane {
     position: relative;
     overflow: hidden;
     width: 200px;
     height: 200px;
     background-color: rgb(0, 110, 255);
     border-radius: 50%;
     animation: changeBackground 5s infinite linear;
   }
   
   
   .plane {
     position: absolute;
     left:110px;
     top:50px;
     animation: move 3s linear infinite;
   }
   
   @keyframes move {
     0% {transform: translateY(0) rotateX(0);}
     25% {transform: translateY(-30px) rotateX(40deg);}
     50% {transform: rotateX(0) translateY(0);}
     75% {transform: rotateX(-40deg) translateY(30px);}
     100% {transform: rotateX(0) translateY(0);}
   }
   
   .wingRight {
     position: absolute;
     width: 0;
     height: 0;
     border-left: 22.5px solid transparent;
     border-right: 12.5px solid transparent;
     border-bottom: 115px solid rgb(144, 144, 150);
     transform: rotate(61deg); 
     z-index:4;
   }
   
   .bottom {
     position: relative;
     width: 0;
     height: 0;
     border-left: 12.5px solid transparent;
     border-right: 12.5px solid transparent;
     border-bottom: 25.5px solid #676d70;
     top: 71px;
     transform: rotate(-5deg);
     left:-52.5px;
     z-index:3;
   }
   
   .top {
     position:absolute;
     width: 0;
     height: 0;
     border-left: 10px solid transparent;
     border-right: 10px solid transparent;
     border-top: 30px solid #c1c7c9;
     top: 65px;
     transform: rotate(5deg);
     left:-60px;
     z-index:1;
   }
   
   .middle {
     position: absolute;
     width: 0;
     height: 0;
     border-left: 15px solid transparent;
     border-right: 15px solid transparent;
     border-bottom: 135px solid #c1c7c9;
     top: -13.5px;
     transform: rotate(72deg);
     left:-7.5px;
     z-index:2;
   }
   
   .wingLeft {
     position: absolute; 
     width: 0;
     height: 0;
     border-left: 25px solid transparent;
     border-bottom: 135px solid white;
     transform: rotate(78deg) skewY(-35deg); 
     left:-18.5px;
     z-index:3;
     top:-30px;
   }
   
   .clouds {
     position: absolute;
     top:50px;
     left:100px;
   }
   
   @keyframes cloud {  
     from{ 
       left:-150px; 
     }
     to{ 
       left: 400px; 
     }
   }
   
   .cloudOne, .cloudTwo, .cloudThree {
     position: absolute;
     width: 50px;
     height: 25px;
     background-color: #fff;
     border-radius:100px 100px 0 0;
   }
   
   .cloudOne {
     top:0;
     left:0;
     animation: cloud 3s linear infinite reverse;
   }
   
   .cloudTwo {
     top:50px;
     left:100px;
     animation: cloud 2.5s linear infinite reverse;
   }
   
   .cloudThree {
     top:250px;
     left:50px;
     animation: cloud 2.8s linear infinite reverse;
   }
   
   .cloudOne:before, .cloudTwo:before, .cloudThree:before {
     content:"";
     position: absolute;
     width: 25px;
     height: 12.5px;
     background-color: #fff;
     border-radius:100px 100px 0 0;
     left:-20px;
     top:12.5px;
     box-shadow: 65px 0 #fff;
   }
  
    
   .loading-dots {
    height: 20px;
     font-size: 100px;
     animation: dots 2.4s infinite;
     opacity: 0;
   }
   
   .loading-dots:nth-child(1) {
     animation-delay: 0.4s;
   }
   
   .loading-dots:nth-child(2) {
     animation-delay: 0.8s;
   }
   
   .loading-dots:nth-child(3) {
     animation-delay: 1.2s;
   }
   
   @keyframes dots {
     30% {
       -webkit-transform: translateY(-5px) scale(5);
       transform: translateY(-5px) scale(5);
       opacity: 1;
       text-shadow: 0 15px 10px black;
     }
     48% {
       -webkit-transform: scale(5);
       transform: scale(5);
     }
     100% {
       -webkit-transform: translateY(0);
       transform: translateY(0);
       opacity: 0;
     }
   }

   .search-container {
    position: relative;
    width: 200px;
    height: 200px;
}

.lens {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    animation: search 2s infinite alternate;
}

@keyframes search {
    0% { top: 0; left: 0; }
    25% { top: 0; left: 5px; }
    50% { top: 5px; left: 5px; }
    75% { top: 5px; left: 0; }
    100% { top: 0; left: 0; }
}
