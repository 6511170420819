@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes pulse {
    50% {
        opacity: 0;
    }
}

.ql-editor {
    height: 90%;
}

.ql-container {
    height: 20%;
}

.animate-page {
    animation: pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.glassMorphic {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 2px 1px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(9.5px);
    -webkit-backdrop-filter: blur(9.5px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
}

@keyframes translateX {
    from {
        transform: translateX(0) rotate(20deg) scaleY(1.5);
    }

    to {
        transform: translateX(1000%) rotate(20deg) scaleY(1.5);
    }
}

.animatedBlock {
    animation: translateX 2s linear infinite;
}

.gradient-background {
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80%);
}

.react-pdf__Page__canvas {
    height: 200px;
}

@keyframes pulse {
    50% {
        opacity: .8;
    }
}

::-webkit-scrollbar {
    display: none;
}

.example::-webkit-scrollbar {
    display: none;
}

.example {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.animatePulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.jodit-container {
    height: 500px;
}

.jodit-wysiwyg {
    height: 500px;
}

@keyframes objAnimation {
    0% {
        transform: rotate(-15deg);
    }

    50% {
        transform: rotate(15deg);
    }

    100% {
        transform: rotate(-15deg);
    }
}

.object {
    animation: objAnimation 10s infinite;
}

@keyframes ping {
    50%, 100% {
         transform: scale(1);
         opacity: 1;
    }
}
.animate-ping-object_ {
    animation: ping 3s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.jodit-status-bar-link {
    display: none;
}

.jodit-status-bar__item {
    display: none;
}

.jodit-editor__resize {
    display: none;
}

.jodit-status-bar .jodit-status-bar_resize-handle_true ::before {
    display: none;
}

* {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  *::-webkit-scrollbar {
    display: none;
  }

  .image-wrapper {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
 }
 
  


